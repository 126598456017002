button,
a {
  cursor: pointer !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: #fe5e5e;
  color: #fff;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
:where(.css-dev-only-do-not-override-ccdg5a).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #bababa;
  border-color: #bababa;
  background: #f4f4f4;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-form-item .ant-form-item-control-input-content {
  text-align: left;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-checkbox-checked .ant-checkbox-inner {
  background: #00e37d;
  border-color: #00e37d;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #00e37d;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none
}

.ant-select-focused:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none;
  border-color: none;
}

.ant-select-selector {
  padding: 8px !important;
  border-radius: 30px !important;
  border: 1px solid #bababa !important;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-switch.ant-switch-checked,
:where(.css-dev-only-do-not-override-ccdg5a).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #fe5e5e;
}

/* .ant-popconfirm-buttons button{
  background: red;
} */
.add-row-link {
  font-size: 0.8rem;
  font-weight: 600;
  text-decoration: underline;
  color: #00e37d;
}

/* .tbl-hour{
  width: 100%;
 }
 
 .tbl-hour tr td {
  border: 1px solid #dcdcdc;
  padding: 5px 10px 5px 10px; 
 } */
.permission-group {
  width: 100%;
}

.permission-collapse {
  width: 100%;
}

.permission-checkbox-main {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  gap: 1.2rem;
  width: 100%;
}

.permission-checkbox {
  font-size: 0.9rem;
  flex: 0 0 calc(100%/3 - 1.2rem);
  max-width: calc(100%/3 - 1.2rem);
}

.permission-checkbox label {
  margin-right: 10px;
}

.tbl-hour .ant-form-item {
  margin-bottom: 0;
}

.ant-select-selector {
  padding: 0.5rem 1.2rem !important;
}

.addProductInfo .ant-select-selector,
.addProductPricing .ant-select-selector,
.descriptionForm .ant-select-selector {
  padding: 0.4rem 1rem !important;
  height: auto !important;
}

.addProductCategorize .ant-form-item {
  margin-bottom: 1rem;
}

.addProductCategorize .ant-select-selector {
  padding: 0.4rem 1rem !important;
  height: auto !important;
}

.payment-tally-tbl .ant-form-item {
  margin-bottom: 0.6rem;
}

.variant-product-popup .ant-form-item-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
}

.variant-product-popup .ant-radio-group .ant-radio-button-wrapper {
  padding: 3px 24px;
  height: auto;
}

.variant-product-popup .ant-radio-group .ant-radio-button-wrapper:first-child {
  border-start-start-radius: 20px;
  border-end-start-radius: 20px;
}

.variant-product-popup .ant-radio-group .ant-radio-button-wrapper:last-child {
  border-start-end-radius: 20px;
  border-end-end-radius: 20px;
}

.variant-product-popup .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #000;
}

.variant-product-popup .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #00e37d;
  background: #00e37d;
  color: #fff;
}

.disabled-div {
  pointer-events: none;
  /* Disable interactions */
  opacity: 0.5;
  /* Make it visually appear disabled */
  background-color: #f5f5f5;
  /* Optional: light gray background for effect */
}

.custom-phone-input {
  border: 1px solid #d9d9d9;
}

.PhoneInput--focus {
  border: none !important;
  outline: none;
}

.prompt-color {
  color: #00e37d;
}

.saleHistory-table table {
  white-space: nowrap;
}

.productslisting-table table {
  white-space: nowrap;
}

.productslisting-table table th {
  /* right: -25px !important; */
}

.productslisting-table table td {
  /* right: -25px !important; */
}

.productCategorylisting-table table {
  white-space: nowrap;
}

.brandsListing-table table {
  white-space: nowrap;
}

.purchanseListing-table {
  white-space: nowrap;
}

.purchanseListing-table table th {
  /* right: -25px !important; */
}

.purchanseListing-table table td {
  /* right: -25px !important; */
}

.pop-confirm-btn .transparent-switch-button {
  padding: 0px !important;
}

.usersListing-table table {
  white-space: nowrap;
}

.unitListing-table {
  white-space: nowrap;
}

.tagsListing-table table {
  white-space: nowrap;
}

.seasonsListing-table table {
  white-space: nowrap;
}

.inventoryTransferListing-table table {
  white-space: nowrap;
}

.customeDetails-table table {
  white-space: nowrap;
}

.tagsDetail-table table {
  white-space: nowrap;
}

.tiersDetail-table table {
  white-space: nowrap;
}

.roleDeatils-table table {
  white-space: nowrap;
}

.tenantDeatils-table table {
  white-space: nowrap;
}

.packagesDeatils-table table {
  white-space: nowrap;
}

.outletManagementDeatils-table table {
  white-space: nowrap;
}

.registerManagementDetail-table table {
  white-space: nowrap;
}

.taxDetail-table table {
  white-space: nowrap;
}

.basicCardDetails .ant-form-item {
  margin-bottom: 0 !important;
}

.basicInventoryDetails .ant-form-item {
  margin-bottom: 0 !important;
}

.categoriesMultipleSelectList .ant-select-multiple {
  height: auto !important;
}

.categoriesMultipleSelectList .ant-select-multiple .ant-select-selector {
  border-radius: 8px !important;
}

.primarInformation-Details .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-tooltip.sidebarMenuTooltip {
  width: 100% !important;
  max-width: max-content !important;
  height: auto !important;
  background: white !important;
  color: #fe5e5e !important;
}

.ant-tooltip.locationTooltip {
  width: 100% !important;
  max-width: 270px !important;
  height: auto !important;
  background: white !important;
  color: white !important;
}

.ant-tooltip.locationTooltip .ant-tooltip-arrow {
  position: absolute;
  bottom: 0px !important;
  left: 50% !important;
}

.ant-tooltip.locationTooltip .ant-tooltip-arrow::before {
  background: white !important;
}

.ant-tooltip.locationTooltip .ant-tooltip-content {
  position: relative;
  background: white !important;
  color: white !important;
}

.ant-tooltip.locationTooltip .ant-tooltip-inner {
  min-width: unset !important;
  min-height: unset !important;
  padding: 0px !important;
  color: unset !important;
  text-align: unset !important;
  text-decoration: unset !important;
  word-wrap: unset !important;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.08), 0 12px 15px -4px rgba(0, 0, 0, 0.12), 0 9px 40px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.ant-tooltip.locationTooltip .ant-tooltip-inner h3 {
  position: relative;
  background: #f7f7f7;
  border-bottom: 1px solid #eef2f4;
  padding: 8px 15px;
  color: black;
  font-size: 22px;
  font-weight: 700;
  /* margin-bottom: 8px; */
}

.ant-tooltip.locationTooltip .ant-tooltip-inner table {
  background: white;
  padding: 8px 15px !important;
}

.ant-tooltip.locationTooltip .ant-tooltip-inner table tbody tr {
  border-top: 1px solid #eef2f4;
}

.ant-tooltip.locationTooltip .ant-tooltip-inner table tbody tr td {
  padding: 15px 6px;
  color: rgb(29, 29, 29);
  font-weight: 700;
  font-size: 14px;
}

.custom-table-dic-header {
  background-color: #fafafa;
  border: none;
  height: 60px;
  border-radius: 20px;
}

.row-custom-table-body {
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
}

/* sidebarToggle css */
.sidebarParentDiv {
  transition: all 0.4s;
}

/* .sidebarParentDiv.sidebarCollapse:hover{
  width: 13rem !important;
} */

.sidebarParentDiv.sidebarCollapse {
  width: 6rem !important;
}

.sidebarParentDiv.sidebarCollapse .sidebarHeadWrap {
  justify-content: center !important;
}

/* .sidebarParentDiv.sidebarCollapse:hover .sidebarHeadWrap {
  justify-content: space-between !important;
} */

.sidebarParentDiv.sidebarCollapse .sidebarLogo {
  display: none;
}

/* .sidebarParentDiv.sidebarCollapse:hover .sidebarLogo{
  display: block;
} */

.sidebarParentDiv.sidebarCollapse .sidebarToggleButton img {
  transform: rotate(180deg);
}

.sidebarParentDiv.sidebarCollapse .sidebarItemList li .sidebarItemListing {
  justify-content: center;
}

.sidebarParentDiv.sidebarCollapse .sidebarItemList li .sidebarItemListing span.sidebarItemListingContent {
  display: none;
}

/* .sidebarParentDiv.sidebarCollapse:hover .sidebarItemList li .sidebarItemListing span{
  font-size: 0.875rem;
} */

/* .sidebarParentDiv.sidebarCollapse .sidebarItemList li .sidebarItemListing span.toggleArrow {
  display: none;
} */

/* .sidebarParentDiv.sidebarCollapse:hover .sidebarItemList li .sidebarItemListing span.toggleArrow{
  display: block;
} */

/* .sidebarParentDiv.sidebarCollapse .sidebarItemList li .sidebarItemListing span:nth-child(3) {
  display: none;
} */

.sidebarParentDiv .sidebarItemList .ant-row {
  display: unset !important;
  flex-flow: unset !important;
  min-width: unset !important;
}

.sidebarParentDiv.sidebarCollapse .sidebarItemList .ant-row.sidebarItemListTooltip {
  display: block;
}

.sidebarItemList li {
  margin-bottom: 0.75rem !important;
}

.sidebarCollapse {
  position: relative !important;
}

.sidebarCollapse .sidebarItemListingSubmenu {
  position: fixed;
  top: 116px;
  left: 116px;
  width: 200px;
  z-index: 9999;
  background: #e8f0fe;
}

@media(max-width: 1439px) {

  .addProductInfo .ant-select-selector,
  .addProductPricing .ant-select-selector,
  .descriptionForm .ant-select-selector,
  .addProductCategorize .ant-select-selector {
    padding: 0.13rem 1rem !important;
  }
}

.discard-button {
  background: #e8f0fe;
  border: 1px solid #e8f0fe;
  padding: 5px 10px;
  border-radius: 10px;
}

.discard-button:hover {
  background: white;
  border: 1px solid #e8f0fe;
  padding: 5px 10px;
}


/* tabs styling */
.filtersTabMain .ant-tabs-nav-list{
  width: 100%;
}

.filtersTabMain .ant-tabs-tab{
  padding: 20px 12px;
  color: #fe5e5e;
  text-align: left;
  margin: 0px !important;
  font-weight: 600;
  background: #f9f9f9;
  border-right: 1px solid #e5e7eb;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  flex: 1 1;
}

.filtersTabMain .ant-tabs-tab:nth-last-child(2){
  border-right: none !important;
}

.filtersTabMain .ant-tabs-tab:hover{
  color: #fe5e5e;
}

.filtersTabMain .ant-tabs-tab.ant-tabs-tab-active{
  color: #fe5e5e !important;
  background-color: transparent;
  --ant-tabs-ink-bar-color: #fe5e5e !important;
}

.filtersTabMain .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fe5e5e;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
  height: 5px;
  background: #fe5e5e;
}

.antRAdioWrap span{
  display: flex;
  align-items: center;
  gap: 8px;
}

.daysPickerWrap .ant-select-selector{
  padding: 1rem 1.5rem !important;
}