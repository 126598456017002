@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0.80rem;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 12rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 241, 237, 0);
    border-radius: 100vh;
    border: 0.3rem solid #fff;
    transition: 1s all ease-in-out;
  }

  .scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 241, 237, 1);
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #c0a0b9;
  }
} */